<template>
  <div>
    <v-btn
      class="mb-2"
      color="primary"
      small
      outlined
      @click="$refs.file.click()"
    >
      {{ $attrs.multiple === '' ? `${chooseWord} Files` : `${chooseWord} File` }} {{ files.length > 0 ? `(${files.length})` : null }}
    </v-btn>

    <v-row>
      <v-col
        v-for="(file, key) in [ ...files, ...existingFiles]"
        :key="key"
        class=""
      >
        <img
          :src="createPreviewUrl(file)"
          class="img-preview"
        >
        <v-progress-linear
          :value="uploadPercentages[file.id]"
          v-if="uploadPercentages[file.id] < 100"
          class="img-progress"
        />
      </v-col>
    </v-row>

    <input type="file" ref="file" :accept="image ? 'image/*' : null" :capture="image ? 'environment' : null" @change="handleFileUpload" v-bind="$attrs" hidden />
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import HasVModel from '@/mixins/HasVModel'

export default {
  name: 'FileUpload',

  mixins: [ HasVModel ],

  props: {
    fileDescription: {
      type: String,
      required: false,
      default: () => { return null }
    },

    image: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    existingFiles: {
      type: Array,
      required: false,
      default: () => { return [] }
    },

    removeOnUploadSuccess: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },
  },

  data () {
    return {
      error: {},
      uploadDisk: 'S3',
      files: [],
      uploadedFiles: [],
      uploadPercentages: {}
    }
  },

  computed: {
    chooseWord () {
      return this.image ? 'Capture' : 'Choose'
    }
  },

  methods: {
    createPreviewUrl (file) {
      if (file.url) {
        return file.url
      }
      return URL.createObjectURL(file)
    },

    handleFileUpload () {
      this.$refs.file.files.forEach(file => {
        const fileId = Math.random() * 100000000000000000
        file.id = fileId
        this.$set(this.uploadPercentages, fileId, 0)
        this.files.push(file)
        this.submitFiles()
      })
    },

    submitFiles () {
      this.files.forEach(file => {
        if (this.uploadPercentages[file.id] === 0) {
          this.$api.persist('post', {
            path: 'files/upload-url'
          })
            .then(data => {
              let formData = new FormData()

              Object.keys(data.inputs).forEach(inputKey => {
                formData.append(inputKey, data.inputs[inputKey])
              })
              formData.append('file', file)

              axios.post(data.attributes.action,
                formData,
                {
                  headers: {
                      'Content-Type': data.attributes.enctype
                  },
                  withCredentials: false,
                  onUploadProgress: progressEvent => {
                    this.uploadPercentages[file.id] = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                  }
                }
              ).then(() => {
                axios.post(data.attributes.complete_url,
                  {
                    mime_type: file.type,
                    size: file.size,
                    name: file.name,
                    description: this.fileDescription || `Atlas File Upload - ${file.name}`,
                })
                  .then(({ data : { data: upload } }) => {
                    this.$emit('file:uploaded', upload)
                    this.localValue.push(upload)
                    if (this.removeOnUploadSuccess) {
                      this.files.splice(this.files.indexOf(file), 1)
                    }
                  })
              })
              .catch(error => {
                this.error = error
              })
            })
            .catch(error => {
              this.error = error
            })
        }
      })
    }
  }
}
</script>
<style type="text/css" scoped="">
  .img-preview {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
    margin-top: 7px;
  }
  .img-progress {
    width: 100%;
    max-width: 80px;
    border-radius: 5px;
  }
</style>
