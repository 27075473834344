<template>
  <tr>
    <td>
      <strong v-if="file.pivot">{{ file.pivot.key_label }}</strong>
    </td>
    <td v-if="!hideFileName">
      {{ file.name }}
    </td>
    <td>
      <v-tooltip v-if="duplicate" top>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            color="orange"
          >
            mdi-alert-circle
          </v-icon>
        </template>
        <span>This image is a duplicate of another with the same name</span>
      </v-tooltip>
    </td>
    <td class="py-1">
      <v-dialog
        v-model="dialog"
        width="800"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="file.mime_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
            x-large
            v-bind="attrs"
            v-on="on"
          >
            mdi-file-word
          </v-icon>
          <v-icon
            v-else-if="file.mime_type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
            x-large
            v-bind="attrs"
            v-on="on"
          >
            mdi-file-excel
          </v-icon>
          <v-icon
            v-else-if="file.mime_type === 'application/pdf'"
            x-large
            v-bind="attrs"
            v-on="on"
          >
            mdi-file-pdf
          </v-icon>
          <v-icon
            v-else-if="file.mime_type === 'video/mp4' || file.mime_type === 'video/quicktime'"
            x-large
            v-bind="attrs"
            v-on="on"
          >
            mdi-video
          </v-icon>
          <v-icon
            v-else-if="file.mime_type === 'application/x-zip-compressed'"
            x-large
            v-bind="attrs"
            v-on="on"
          >
            mdi-folder-zip
          </v-icon>
          <img
            v-else
            :src="file.url"
            class="img-preview"
            v-bind="attrs"
            v-on="on"
          >
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">{{ file.name }}</v-card-title>

          <v-card-text class="pt-2">
            <img
              v-if="file.mime_type === 'image/jpeg' || file.mime_type === 'image/png' || file.mime_type === 'image/jpg'"
              class="img"
              :src="file.url"
            >
            <video v-if="file.mime_type === 'video/mp4'" width="100%" height="500" controls>
              <source :src="file.url" type="video/mp4">
            </video>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="darken-1"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
            <DeleteDialog
              record-name="File"
              delete-text="Are you sure you want to delete this file?"
              delete-permission="UPDATE_BOOKINGS"
              :url="file.signed_delete_url"
              :x-small="false"
              @deleted="dialog = false; $emit('deleted', file)"
            />
            <v-btn
              color="primary"
              text
              :href="file.url"
            >
              Download
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </td>
<!--
    <td>
      {{ file.created_by }}
    </td>
-->
  </tr>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog'

export default {
  name: 'BookingFileRow',

  components: {
    DeleteDialog
  },

  props: {
    file: {
      required: true,
      type: Object
    },

    hideFileName: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    duplicate: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  data () {
    return {
      dialog: false
    }
  }
}
</script>

<style lang="css" scoped>
  .img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  .img-preview {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: -5px;
  }
</style>