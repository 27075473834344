export default {
  data () {
    return {
      fileError: {},
      files: [],
      fileTypes: [],
      meta: {},
      links: {}
    }
  },

  mounted () {
    if (this.loadTypes) {
      this.getFileTypes()
    }
  },

  computed: {
    loadTypes () {
      return true
    },

    bookingId () {
      return this.booking.id
    },

    bookingBikeId () {
      return this.bike.id
    },

    filesRequestParams () {
      return {
        limit: 30
      }
    }
  },

  methods: {
    handleFileUpload (file, key) {
      this.fileError = {}
      this.$api.persist('put', {
        path: `bookings/${this.bookingId}/bikes/${this.bookingBikeId}/file`,
        object: {
          file: file.id,
          key: key
        }
      })
        .then(() => {
          this.getBikeFiles()
          if (this.$refs.fileSuccessBar) {
            this.$refs.fileSuccessBar.open()
          }
        })
        .catch(error => {
          this.fileError = error
          if (this.$refs.errorBar) {
            this.$refs.errorBar.open()
          }
        })
    },

    getBikeFiles () {
      this.fileError = {}
      this.$api.get({
        path: `bookings/${this.bookingId}/bikes/${this.bookingBikeId}/files`,
        params: {
          ...{ with: 'createdBy' },
          ...this.filesRequestParams
        }
      })
        .then(({ data: files, meta, links }) => {
          this.files = files
          this.meta = meta
          this.links = links
        })
        .catch(error => {
          this.fileError = error
          if (this.$refs.errorBar) {
            this.$refs.errorBar.open()
          }
        })
    },

    loadMoreFiles () {
      this.fileError = {}
      this.$api.get({
        url: this.links.next,
        params: {
          ...{ with: 'createdBy' },
          ...this.filesRequestParams
        }
      })
        .then(({ data: files, meta, links }) => {
          this.files = [
            ...this.files,
            ...files
          ]
          this.meta = meta,
          this.links = links
        })
        .catch(error => {
          this.fileError = error
          if (this.$refs.errorBar) {
            this.$refs.errorBar.open()
          }
        })
    },

    getFileTypes () {
      this.$api.get({
        path: 'files/types'
      })
        .then(types => {
          Object.keys(types).forEach(typeKey => {
            this.fileTypes.push({
              text: types[typeKey],
              value: typeKey
            })
          })
        })
    }
  }
}