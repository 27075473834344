<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">File Type</th>
          <th v-if="!hideFileName" class="text-left">File Name</th>
          <th></th>
          <th class="text-left">Preview</th>
          <!-- <th class="text-left">Uploaded By</th> -->
        </tr>
      </thead>
      <tbody>
        <FileRow
          v-for="file in files"
          :key="file.id"
          :file="file"
          :duplicate="fileDuplicates.includes(file.name)"
          :hide-file-name="hideFileName"
          @deleted="file => $emit('file:deleted', file)"
        />
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import groupBy from 'lodash.groupby'
import pickBy from 'lodash.pickby'
import FileRow from '@/components/files/FileRow'

export default {
  name: 'FilesList',

  props: {
    files: {
      type: Array,
      required: true
    },

    hideFileName: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  components: {
    FileRow
  },

  computed: {
    fileDuplicates () {
      return Object.keys(pickBy(groupBy(this.files, 'name'), (file) => { return file.length > 1 }))
    }
  }
}
</script>

<style lang="css" scoped>
</style>